<template>
  <div>
    <template v-if="eSlip != null">
      <!-- Invoice Start -->
      <section class="bg-light">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10 no-print">
              <!-- toolbar -->
              <div class="d-flex justify-content-end">
                <b-button variant="primary" @click="printInvoice"
                  >Print e-Slip</b-button
                >
              </div>
              <!-- end toolbar -->
            </div>
            <div class="col-lg-10">
              <div class="card shadow rounded border-0">
                <div class="card-body">
                  <div class="invoice-top pb-4 border-bottom">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="logo-invoice mb-2">e-Slip</div>
                        <h6 class="text-muted">Transfer to customer</h6>
                        <div class="d-flex align-items-center">
                          <div>
                            <b-img
                              :src="eSlip.customerLogo"
                              style="max-height: 75px"
                              rounded
                            ></b-img>
                          </div>
                          <div class="ml-2">
                            <h5>{{ eSlip.customerName }}</h5>
                          </div>
                        </div>
                      </div>
                      <!--end col-->

                      <div class="col-md-4 mt-4 mt-sm-0">
                        <h5>DekNerd Co., Ltd.</h5>
                        <address>
                          <strong>Address: </strong>
                          144/14-15 room no. 102<br />
                          Silom 10 rd., Suriyahwong, Bang Ruk, Bangkok 10500
                          <br />
                        </address>
                        <strong>Tax ID.</strong> 0105560163073
                      </div>
                      <!--end col-->
                    </div>
                    <!--end row-->
                  </div>

                  <div class="invoice-middle py-4">
                    <div class="mb-4">
                      <b-row>
                        <b-col>
                          <h6 class="text-muted">Transfer No.</h6>
                          <div>
                            {{ eSlip.transId }}
                          </div>
                        </b-col>
                        <b-col>
                          <template
                            v-if="eSlip.comment != '' && eSlip.comment != null"
                          >
                            <h6 class="text-muted">Note</h6>
                            <div>
                              {{ eSlip.comment }}
                            </div>
                          </template>
                        </b-col>
                      </b-row>
                    </div>

                    <div>
                      <h6 class="text-muted">To Account</h6>
                      <b-row>
                        <b-col>
                          <div class="text-muted">Account Name</div>
                          <div>{{ eSlip.bankAccount.name }}</div>
                        </b-col>
                        <b-col>
                          <div class="text-muted">Bank</div>
                          <div>
                            <span class="text-uppercase">{{
                              eSlip.bankAccount.brand
                            }}</span>
                            (*{{ eSlip.bankAccount.last_digits }})
                          </div>
                        </b-col>
                        <b-col>
                          <div class="text-muted">Date</div>
                          <div>
                            {{
                              eSlip.transferDate
                                | moment("DD MMM YYYY HH:mm:ss")
                            }}
                          </div>
                        </b-col>
                      </b-row>
                    </div>

                    <hr />

                    <div class="mt-4">
                      <b-row>
                        <b-col
                          ><h6 class="text-muted">Amount</h6>
                          <div>{{ eSlip.amount | numFormat("0,0.00") }}</div>
                        </b-col>
                        <b-col
                          ><h6 class="text-muted">Fee</h6>
                          <div>
                            {{ eSlip.fee | numFormat("0,0.00") }} ({{
                              eSlip.feeRate * 100
                            }}%)
                          </div>
                        </b-col>
                        <b-col
                          ><h6 class="text-muted">Net Amount</h6>
                          <div>{{ eSlip.net | numFormat("0,0.00") }}</div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>

                  <div class="invoice-footer border-top pt-4">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="text-sm-left text-muted text-center">
                          <h6 class="mb-0">Customer Services : 022380209</h6>
                        </div>
                      </div>

                      <div class="col-sm-6"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </section>
      <!--end section-->
      <!-- Invoice End -->
    </template>
    <template v-else>
      <div class="text-center p-5">
        <b-spinner
          variant="primary"
          style="width: 3rem; height: 3rem"
        ></b-spinner>

        <div class="mt-4">
          <h4>Loading e-Slip</h4>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { EventBus } from "../../../event-bus";
import { getESlip } from "../../../services/withdrawService";
export default {
  created() {
    // EventBus.$emit("updateLayout");
  },
  data: () => ({
    eSlip: null,
  }),
  mounted() {
    this.init();
  },
  methods: {
    printInvoice() {
      window.print();
    },
    init() {
      getESlip(this.$route.params.id)
        .then((resp) => {
          this.eSlip = resp.data;
        })
        .catch((err) => {
          alert("Can not get receipt information");
        });
    },
  },
};
</script>

<style scoped>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>